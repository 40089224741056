.z-index-10 {
    z-index: 10;
}

.discret-link {
    a, &a {
        font-weight: $font-weight-base;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
