@import-normalize ;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500&display=swap');

@import "variables";
@import '~bootstrap/scss/bootstrap.scss';

@import "init";
@import "parallax";
@import "nav";
@import "homepage";
@import "utilities";
