#home-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #151515;
    z-index: 1000;

    img {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    @keyframes color-transition {
        0% {color: $primary-dark}
        25% {color: #accfdc}
        50% {color: $secondary}
        75% {color: #a2937a}
    }

    .spinner-border {
        width: 80px;
        height: 80px;
        animation: spinner-border .75s linear infinite, color-transition 2s linear infinite;
    }
}

#accueil {
    position: absolute;
    top: 128px;
    left: 0;
    right: 0;
    width: 50%;
    height: 40%;
    margin: 0 auto;
    text-align: center;

    h1 {
        pointer-events: none;
        font-weight: 200;
        font-size: 75px;
        margin: 0;
        text-shadow: 3px 3px 3px black;
    }

    h2 {
        margin-top: -10px;
    }
}

.grecaptcha-badge { visibility: hidden; }
