#parallax-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/bg/background.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;

    #pb-lights {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/bg/lights.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
    }

    #pb-character {
        position: absolute;
        width: 100vw;
        height: 60vh;
        margin: 20vh auto 0;
        left: 0;
        bottom: 0;
        text-align: center;

        > div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('../images/bg/space_ecouteurs.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
        }
    }
}
