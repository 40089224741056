html {
    scroll-behavior: smooth;
}

body {
    padding-bottom: 3rem;
    color: $primary;
    background: #000000;
}

strong {
    font-weight: $font-weight-bold;
}

h2 {
    font-weight: $font-weight-base;
    margin-bottom: 16px;
}

h4 {
    font-weight: $font-weight-base;
    text-decoration: underline;
}

#accueil + * {
    margin-top: 100vh;
}

.main-part {
    padding: 96px 10%;
}

#introduction {
    background-color: rgba(0, 0, 0, .85);

    p {
        font-size: 32px;
        font-weight: $font-weight-base;
    }
}

#biographie {
    background-color: $background-dark;
}

#contact {
    background-color: rgba(0, 0, 0, .65);

    input, textarea {
        background-color: $input-background;
    }
}
