$nav-height: 85px;

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, .85);

    nav {
        min-height: $nav-height;
        display: flex;
        flex-direction: row;
        @media (min-width: 768px) {
            justify-content: center !important;
            align-items: start !important;
        }

        .even-width {
            width: calc(40% - 40px);
            height: 49px;
            line-height: 49px;
            border-bottom: 1px solid $primary;
        }

        .navbar-collapse {
            flex-grow: initial !important;
            display: flex;

            &#navbarCollapse {
                @media (min-width: 768px) {
                    display: none !important;
                }
            }

            &.collapse {
                display: none;
                &.show {
                    display: flex;
                }
            }
        }

        img {
            height: 80px;
        }
    }
}

footer {
    padding: 64px 10%;
}
